import React from 'react';
import Page, { SECTION, HEADING, ROW, TEXT, CONTENT, CONTENT_LI } from '../components/Page';

const Projects = () => (
    <Page>
        <SECTION>
            <HEADING>Cyber Scavenger Hunt</HEADING>

            <CONTENT>
                <a href="https://www.CyberScavengerHunt.com" target='_blank'>https://www.CyberScavengerHunt.com</a>
            </CONTENT>

            <CONTENT>
                Cyber Scavenger Hunt is a free, interactive website that challenges you to think
                like a hacker and start attacking a vulnerable web application penetration.
            </CONTENT>

            <CONTENT>
                💯 for beginners and folks new to cybersecurity!
            </CONTENT>
        </SECTION>

        <SECTION>
            <HEADING>Hacking React</HEADING>

            <CONTENT>
                <a href="https://www.HackingReact.io" target='_blank'>https://www.HackingReact.io</a>
            </CONTENT>

            <CONTENT>
                Hacking React is another interactive website that teaches you best practices
                for securing web applications built with ReactJS.
            </CONTENT>

            <CONTENT>
                You'll be writing more secure code in under 5 minutes!
            </CONTENT>
        </SECTION>

        <SECTION>
            <HEADING>Intro to Hacking Web Applications</HEADING>

            <CONTENT>
                <a href="https://www.youtube.com/playlist?list=PLQb4kK5hIqZTsGS3Qlq-5cwozoiLOpK_W" target='_blank'>NOW FREE ON YOUTUBE!</a>
            </CONTENT>

            <CONTENT>
                My online course!
            </CONTENT>

            <CONTENT>
                4 hours of hands-on practical advice exploring security flaws common to both legacy and modern web applications. 
                By learning how to hack, you'll also be learning what countermeasures are necessary to protect your own applications!
            </CONTENT>
        </SECTION>

    </Page >
);

export default Projects;